<template>
  <div class="body">
    <div style="padding-left: var(--30);padding-right: var(--30);">
      <van-list v-model=page.loading
                :immediate-check="false"
                :finished=page.finished
                :finished-text=page.finishedText
                @load="queryWithdrawalChainRecord()"
                v-if="page.contentList.length > 0">
        <div v-for="(item, index) in page.contentList" :key="index">
          <div class="item_bg margin-top20" style="padding: var(--30)">
            <div class="flex_able">
              <span class="font-size32 font-weightBold line-height44 font-color333333" style="flex: 1">提取</span>
              <span
                  class="font-size32 font-weightBold line-height44 font-color333333">{{
                  item.toActualAmount
                }}{{ item.fromAccountCoinName }}</span>
            </div>
            <div class="margin-top20 font-size30 line-height42 font-color666666 flex_able">
              <span style="flex: 1">提取地址</span>
              <span class="margin-top20 font-size30 line-height42 font-color666666">
              {{ item.walletAddressStr }}
            </span>
            </div>
            <div class="margin-top30"
                 style="width: var(--630);height: var(--1);background: #F3F3F3;border-radius: var(--4);margin-left: auto;margin-right: auto;"></div>
            <div class="flex_able margin-top30">
              <span style="flex: 1" class="font-size28 font-color999999 line-height40">{{ item.addTimeStr }}</span>
              <span v-if="item.status == 3" class="font-size28 line-height40" style="color:#F33D3D;">提取失败</span>
              <span v-else-if="item.status == 2" class="font-size28 line-height40 font-color333333">提取成功</span>
              <span v-else class="font-size28 line-height40" style="color:#FF8F27;">提取中</span>
            </div>
          </div>
        </div>
      </van-list>
      <show-no-data-view v-if="page.contentList.length === 0" />
    </div>
  </div>
</template>

<script>

import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import EncryptUtils from "@/utils/EncryptUtils";
import ShowNoDataView from "@/components/ShowNoDataView.vue";
// import {Toast} from "vant";

export default {
  components: {ShowNoDataView},
  computed: {
    EncryptUtils() {
      return EncryptUtils
    }
  },
  data() {
    return {
      assetType: "",
      page: {
        finished: false,
        pageNo: 1,
        loading: false,
        finishedText: "没有更多了",
        pageSize: 10,
        contentList: [],
      }
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "提取记录"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "true"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "true",
        statusBarBgColor: "#ffffff",
        showStatusBar: "true"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    if (this.$route.query.assetType) {
      this.assetType = this.$route.query.assetType;
      this.queryWithdrawalChainRecord()
    }
  },
  methods: {
    queryWithdrawalChainRecord() {
      let page = this.page
      //此处用查流水来实现模拟分页
      let data = {
        pageNo: page.pageNo,
        pageSize: page.pageSize,
        assetType: this.assetType,
        fromAccountTypeReal: this.assetType,
      }
      Api.queryWithdrawalChainRecord(data).then(res => {
        if (res.code === 100) {
          let resData = res.data.pageInfo,
              list = resData.list;
          if (resData.isLastPage) { // 没有更多了
            page.finished = true;
          }
          page.contentList = page.contentList.concat(this.handleList(list));
          page.pageNo = resData.nextPage;
          page.loading = false;
        } else {
          console.log("出现了网络错误")
          Toast(res.msg);
        }
      })
    },
    handleList(list) {
      list.forEach(item => {
        if (item.walletAddress.startsWith("0x", 0, 2)) {
          item["walletAddressStr"] = item.walletAddress.substring(0, 6) + '...' + item.walletAddress.slice(-4)
        } else {
          item["walletAddressStr"] = item.walletAddress
        }
      })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #F8F8F8;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.item_bg {
  width: var(--690);
  background: #FFFFFF;
  border-radius: var(--10);
}
</style>
